<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}: {{ search_query }}
      </h3>
    </div>

    <div v-if="search_results.length" v-for="searchItem in search_results">
      <div class="box-body">
        <div class="box-header with-border">
          <h4>
            Клиент
          </h4>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="panel panel-default mb-3">
              <div class="panel-heading">
                <h5 class="mb-0">
                  <strong class="font-medium">#{{ searchItem.id }}</strong>
                  <strong class="margin-l-5 font-medium" style="position: absolute;">
                    {{ searchItem.name }}
                  </strong>
                </h5>
                <small class="mt-0">
                  Создан {{ searchItem.created_date }}

                  <span class="label label-default font-small-xxs mt-1 pull-right">
                  Добавлен {{ searchItem.creator_id ? 'сотрудником' : 'через сайт' }}
                </span>
                </small>
              </div>

              <div class="panel-body">
                <div class="row">
                  <div class="col-12 mb-1 mt-1">
                    <p class="mb-1">
                    <span>
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + searchItem.phone" class="font-medium">
                          {{ searchItem.phone }}
                        </a>
                      </span>
                    </p>
                    <p v-if="searchItem.email" class="mb-1">
                    <span>
                      {{ searchItem.email }}
                    </span>
                    </p>
                    <p v-if="searchItem.city" class="mb-1">
                      <strong>Город: </strong>
                      <span>
                      {{ searchItem.city.title }}
                    </span>
                    </p>
                    <p v-if="searchItem.passport" class="mb-1">
                      <strong>Паспорт: </strong>
                      <span>
                      {{ searchItem.passport }}
                    </span>
                    </p>
                    <p v-if="searchItem.address" class="mb-1">
                      <strong>Адрес: </strong>
                      <span>
                      {{ searchItem.address }}
                    </span>
                    </p>
                    <p v-if="searchItem.address" class="mb-1">
                      <strong>Водительское удостоверение: </strong>
                      <span>
                      {{ searchItem.driver_license }}
                    </span>
                    </p>
                    <p v-if="searchItem.creator_id" class="mb-1">
                      <strong>Создал: </strong>
                      <span>
                      {{ searchItem.creator.title }}
                    </span>
                    </p>
                    <p v-if="searchItem.last_editor_id" class="mb-1">
                      <strong>Обновил </strong>
                      <span>
                      {{ searchItem.editor.title }}
                    </span>
                    </p>
                    <p v-if="searchItem.updated_date" class="mb-1">
                      <strong>Дата обновления </strong>
                      <span>
                      {{ searchItem.updated_date }}
                    </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <router-link :to="{ name: 'client.details', params: { clientId: searchItem.id }}"
                             class="btn btn-success pull-right margin-l-5"
                             title="Детали"
                             type="button">
                  <i class="fa fa-info-circle"></i>
                </router-link>
                <router-link :to="{ name: 'client.details', params: { clientId: searchItem.id, currentTab: 'История' }}"
                             class="btn btn-default pull-right"
                             title="История"
                             type="button">
                  <i class="fas fa-book"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="box-body">
        <div class="box-header with-border">
          <h4>
            Автомобили {{ searchItem.cars.length === 0 ? 'не найдены' : '' }}
          </h4>
        </div>

        <div class="row" v-if="searchItem.cars.length">
          <div class="col-lg-4 col-md-12" v-for="car in searchItem.cars">
            <div class="panel panel-default mb-3">
              <div class="panel-heading" :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
                <h5 class="car-title mb-0">
                  #{{ car.id }}
                  <strong class="margin-l-5" style="position: absolute;">
                    {{ car.title }}
                  </strong>
                </h5>
                <span class="font-medium font-small mt-0">
                  <span v-if="car.owner_type === 'client'">Клиент</span>
                  <span v-if="car.owner_type === 'company'">Компания</span>
                  <span v-if="car.owner_type === 'exchange'">Обмен/выкуп</span>
                </span>
                <span class="label label-success font-medium font-small-xs" style="padding: 2px 3px; margin-left: 5px;">
                  {{ car.location === "owner" ? "У владельца" : "В салоне" }}
                </span>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="col-12 col-lg-5">
                    <img v-if="car.main_photo" :src="car.main_photo" class="img-fluid" :alt="car.title"
                         style="min-height: 100px;">
                    <img :alt="car.title" class="img-fluid" src="../../assets/img/no-car-photo-240-140.png" v-else>

                    <ul class="list-group list-group-unbordered">
                      <li class="list-group-item no-border pt-0 pb-1">
                        <strong class="font-scale-1">{{ car.price }} $</strong>
                        <small v-if="car.old_price && car.is_top_price"
                               class="font-medium text-red text-muted margin-l-5 font-small">
                          {{ car.old_price }}$
                        </small>
                      </li>
                      <li class="list-group-item no-border medium-line-height text-muted font-small pb-1 pt-0"
                          v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id !== $store.getters['auth/authUser'].id">
                        {{ car.created_date }}
                        <small>
                          <br/>
                          Ответственный <strong>{{ car.creator.name }}</strong>
                        </small>
                      </li>
                      <li class="list-group-item no-border medium-line-height text-muted font-small pt-0"
                          v-if="car.editor">
                        {{ car.updated_date }}
                        <small>
                          <br/>
                          Обновил <strong>{{ car.editor.name }}</strong>
                        </small>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-7">
                    <ul class="list-group list-group-unbordered">
                      <li class="list-group-item pb-1">
                        <strong>{{ car.branch.title }}</strong>
                        <small v-if="car.branch.address">
                          ({{ car.branch.address }})
                        </small>
                        <small style="letter-spacing: -.035rem;">
                          <strong v-if="car.specification.engine_title" class="text-black font-small">
                            <span class="font-light">/</span> {{ car.specification.engine_title }}
                          </strong>
                          / {{ car.specification.fuel.title }}
                          / {{ car.specification.gearbox.title }}
                          / {{ car.specification.race }} тыс. км.
                          / {{ car.specification.transmission.title }} привод
                          / {{ car.specification.color.title }}
                          <span v-if="car.origin">/ {{ car.origin.title }}</span>
                          / <strong class="text-black font-small">{{ car.year }}</strong> г.
                        </small>
                      </li>
                      <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0; text-align: right;">
                        <clipboard-block :data="car.specification.reg_number" custom_class="btn-default"/>
                        <span class="label label-primary margin-r-5">
                  <strong style="font-size: 15px; letter-spacing: .03rem;">
                    {{ car.specification.reg_number }}
                  </strong>
                </span>

                        <span class="label label-primary margin-l-5">
                  <strong style="font-size: 14px; letter-spacing: .001rem;">
                    {{ car.specification.vin_code }}
                  </strong>
                </span>
                        <clipboard-block :data="car.specification.vin_code" custom_class="btn-default"/>
                      </li>
                      <li class="list-group-item no-border font-small"
                          style="text-align: right;">
                        <i class="fa fa-eye text-muted font-light" style="margin-right: 2px;"/> {{ car.views }}
                      </li>
                    </ul>
                  </div>
                  <div v-if="car.sold"
                       class="col-12"
                       :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
                    Продано за
                    <strong class="font-scale-1">
                      {{ car.sold.sum }}$
                    </strong>
                    владельцу
                    <strong class="font-scale-1">
                      {{ car.sold.owner_sum }}$
                    </strong>
                    комиссия
                    <strong class="font-scale-1">
                      {{ (car.sold.sum - car.sold.owner_sum) }}$
                    </strong>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-lg-12 pt-2">
                    <router-link :to="{ name: 'car.details', params: { carId: car.id }}"
                                 class="btn btn-success pull-right margin-l-5"
                                 title="Детали"
                                 type="button">
                      <i class="fa fa-info-circle"></i>
                    </router-link>

                    <router-link :to="{ name: 'car.details', params: { carId: car.id, currentTab: 'Акты осмотра' }}"
                                 class="btn btn-info pull-right margin-l-5"
                                 title="Акты осмотра"
                                 type="button">
                      <i class="fa fa-screwdriver-wrench"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="box-body">
        <div class="box-header with-border">
          <h4>
            Задачи {{ searchItem.tasks.length === 0 ? 'не найдены' : '' }}
          </h4>
        </div>

        <div class="row" v-if="searchItem.tasks.length">
          <div class="col-lg-4 col-md-12" v-for="task in searchItem.tasks">
            <div class="panel panel-default mb-3">
              <div class="panel-heading" :style="'background:' + task.type.custom_color">
                <h5 class="mb-0">
                  <strong class="font-medium">#{{ task.id }}</strong>
                  <strong class="margin-l-5 font-medium" style="position: absolute;">
                    {{ task.type.name }}
                  </strong>

                  <span v-if="task.type_status"
                        class="label label-default text-white font-medium pull-right"
                        :style="'padding: 2px 6px; font-size: 14px; background:' + task.type_status.custom_color.replace('0.25', '1')">
                    {{ task.type_status.name }}
                  </span>
                </h5>
                <small class="mt-0">
                  {{ task.created_date }} от
                  <span class="font-medium" v-if="task.creator">
                    {{ task.creator.name }}
                  </span>
                  <span class="font-medium" v-if="!task.creator">
                    клиента
                  </span>

                  <span v-if="task.creator || task.client_id" class="label font-small-xxs mt-1 pull-right"
                        :class="task.creator ? 'label-primary' : ((!task.creator && task.client_id) ? 'label-success' : 'label-default')">
                    {{ task.creator ? 'Сотрудник' : ((!task.creator && task.client_id) ? 'Клиент' : '') }}
                  </span>

                  <template v-if="task.tags.length">
                    <span v-for="tag in task.tags" class="label font-small-xxs mt-1 pull-right" :class="tag.label_color_class">
                      {{ tag.name }}
                    </span>
                  </template>
                </small>
              </div>

              <div class="panel-body">
                <div class="row">
                  <div class="col-12 mb-1 mt-1">
                    <p v-if="task.car" class="mb-0">
                      <strong>Автомобиль: </strong>
                      <span>{{ task.car.title }} (ID: {{ task.car.id }} {{ task.car.branch.title }})</span>
                    </p>
                    <p v-else class="mb-0">
                      <strong class="text-muted">Автомобиль не выбран</strong>
                    </p>
                    <p v-else class="mb-1">
                      <strong class="text-muted">Клиент не выбран</strong>
                    </p>
                    <p v-if="(task.responsible && $store.getters['auth/authUser'].id !== task.responsible_id) || !task.responsible"
                       class="mb-1">
                      <strong>Исполнитель: </strong>
                      <span v-if="task.responsible">
                        {{ task.responsible.name }} (ID: {{ task.responsible_id }} {{ task.responsible.branch ? task.responsible.branch.title : '' }})
                      </span>
                              <span class="font-small text-muted" v-else>
                        Не назначен
                      </span>
                    </p>
                    <p v-if="task.branch" class="mb-1">
                      <strong>Филиал: </strong>
                      <span>
                        {{ task.branch.title }}, <span class="text-muted">{{ task.branch.address }}</span>
                      </span>
                    </p>
                    <p v-if="task.department" class="mb-1">
                      <strong>Отдел: </strong>
                      <span>
                        {{ task.department.title }}
                      </span>
                    </p>
                    <p v-if="task.term_date" class="mb-1">
                      <strong>Срок выполнения: </strong>
                      <span class="font-medium">{{ task.term_date }}</span>
                    </p>

                    <task-item-details-block v-if="Object.keys(task.details).length"
                                             :task_details="task.details"
                                             :task_car_price="task.car ? task.car.price : 0"/>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <router-link :to="{ name: 'task.details', params: { taskId: task.id }}"
                             class="btn btn-success pull-right margin-l-5"
                             title="Детали"
                             type="button">
                  <i class="fa fa-info-circle"></i>
                </router-link>
                <router-link :to="{ name: 'task.details', params: { taskId: task.id, currentTab: 'История' }}"
                             class="btn btn-default pull-right"
                             title="История"
                             type="button">
                  <i class="fas fa-book"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="box-body">
        <div class="box-header with-border">
          <h4>
            Объявления {{ searchItem.ads_cars.length === 0 ? 'не найдены' : '' }}
          </h4>
        </div>

        <div class="row" v-if="searchItem.ads_cars.length">
          <div class="col-lg-4 col-md-12" v-for="ads_car in searchItem.ads_cars">
            <div class="panel panel-default mb-3">
              <div class="panel-heading">
                <h5 class="mb-0">
                  <strong class="font-medium">#{{ ads_car.id }}</strong>
                  <strong class="margin-l-5 font-medium" style="position: absolute;">
                    {{ ads_car.mark.name }} {{ ads_car.model.name }}
                  </strong>
                </h5>
                <small class="mt-0">
                  Добавлено {{ ads_car.date_title }}
                </small>
              </div>

              <div class="panel-body">
                <div class="row">
                  <div class="col-12 mb-1 mt-1">
                    <p v-if="ads_car.year" class="mb-1">
                      <strong>Год: </strong>
                      <span>
                        {{ ads_car.year }}
                      </span>
                    </p>
                    <p v-if="ads_car.city" class="mb-1">
                      <strong>Город: </strong>
                      <span>
                        {{ ads_car.city.title }}
                      </span>
                    </p>
                    <p v-if="ads_car.price" class="mb-1">
                      <strong>Стоимость: </strong>
                      <span>
                        {{ ads_car.price }}$
                      </span>
                    </p>
                    <p v-if="ads_car.ads_count" class="mb-1">
                      <strong>Кол-во объявлений: </strong>
                      <span>
                        {{ ads_car.ads_count }}
                      </span>
                    </p>
                    <p v-if="ads_car.ad_source" class="mb-1">
                      <strong>Источник: </strong>
                      <span>
                        {{ ads_car.ad_source }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <a :href="(ads_car.ad_source === 'AutoRIA' ? 'https://auto.ria.com/ru' : 'https://olx.ua') + ads_car.link"
                   class="btn btn-info" target="_blank">
                  <i class="fas fa-external-link-square-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="box-body">
        <div class="text-center my-5">
          <h4 class="text-muted">Результаты не найдены</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ClipboardBlock from "@/components/blocks/clipboard-block.vue";

Vue.component('task-item-details-block', (resolve) => {
  return require(['./../tasks/item_blocks/details-block.vue'], resolve);
});

export default {
  name: "search",
  components: { ClipboardBlock },

  props: {
    search_query: {
      type: String,
      required: true,
      default: null,
    },
    search_results: {
      type: Array | Object,
      required: true,
      default: [],
    },
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Результаты поиска",
  }),

  async created() {
    try {

    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {},

  methods: {},
};
</script>

<style scoped>

</style>
